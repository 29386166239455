import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import Content from 'components/shared/Content';
import Heading from 'components/shared/Heading';
import matexiLogo from 'assets/icons/matexi-white.svg';
import invLogo from 'assets/icons/logo.svg';
import Links from 'components/Navigation/Links';
import MenuBtn from 'components/Navigation/MenuBtn';
import Button from 'components/shared/Button';
// import SocialMedia from 'components/Header/SocialMedia';
import useMedia from 'hooks/useMedia';
import scrollToSection from 'utils/scrollToSection';
import ScrollableSection from 'react-update-url-on-scroll';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';
import { NavigationContext } from 'contexts/NavigationContext';

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 650px;
  overflow: visible;
  ${({ theme }) => theme.mq.xs} {
    height: 100%;
  }
`;

const StyledContent = styled(Content)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;
  ${({ theme }) => theme.mq.s} {
    top: 40px;
  }
  ${({ theme }) => theme.mq.lg} {
    top: 60px;
  }
`;

const HeaderContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.mq.md} {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  position: relative;
  pointer-events: none;
  user-select: none;
  height: 100%;
  ${({ theme }) => theme.mq.xs} {
    height: auto;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    user-select: none;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.1)
    );
    z-index: 1;
    box-shadow: inset 0px -100px 75px 5px ${({ theme }) => theme.colors.dark100};
  }
`;

const StyledInnerWrapper = styled(InnerWrapper)`
  align-items: center;
  margin-bottom: 10px;
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 25px;
  }
`;

const ContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: auto;
  ${({ theme }) => theme.mq.md} {
    margin-left: 40px;
  }
`;

const Logo = styled.a`
  width: 150px;
  margin: 0 0 20px 5px;
  ${({ theme }) => theme.mq.s} {
    width: 201px;
    margin: 0 0 25px 5px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin: 0 25px 0 0;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.a`
  position: absolute;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  width: 235px;
  ${({ theme }) => theme.mq.s} {
    position: static;
    width: 300px;
    transform: none;
    bottom: auto;
    left: auto;
  }
  ${({ theme }) => theme.mq.md} {
    width: 350px;
  }
  ${({ theme }) => theme.mq.xl} {
    width: 471px;
  }
`;

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 57%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.white};
  z-index: 5;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`;

const Subtitle = styled.p`
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.regular};
  ${({ theme }) => theme.mq.s} {
    font-size: 2.3rem;
    line-height: 2.6rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 2.6rem;
    line-height: 3rem;
  }
  span {
    display: block;
    margin-top: 5px;
  }
`;

const HeaderButton = styled(Button)`
  max-width: initial;
  width: auto;
  margin: 3rem 0 0 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

const Header = ({ image, imageAlt, heading, description }) => {
  const matchesXL = useMedia('(min-width: 1200px)');
  const matchesLG = useMedia('(min-width: 992px)');

  const { initialSearchParam } = useContext(NavigationContext);

  return (
    typeof window !== 'undefined' && (
      <Wrapper>
        <ScrollableSection name={`${initialSearchParam}#o-inwestycji    `}>
          <TitleWrapper>
            <HeaderContent>
              <Heading dangerouslySetInnerHTML={{ __html: heading }} />
              <br />
              <Subtitle
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              <HeaderButton
                onClick={() => {
                  scrollToSection('#mieszkania');
                  emitMatomoEvent({
                    event: eventsNames.click_wybierz_mieszkanie,
                  });
                }}
              >
                Wybierz apartament
              </HeaderButton>
            </HeaderContent>
          </TitleWrapper>
          <StyledContent>
            <InnerWrapper>
              <IconsWrapper>
                {!matchesLG && (
                  <Logo
                    href="https://matexipolska.pl/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <Icon src={matexiLogo} alt="logo matexi" full />
                  </Logo>
                )}
                <IconWrapper href="/">
                  <Icon src={invLogo} alt="grzybowska 37" full />
                </IconWrapper>
              </IconsWrapper>
              <ContentInnerWrapper>
                <StyledInnerWrapper>
                  {matchesLG && (
                    <Logo
                      href="https://matexipolska.pl/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <Icon src={matexiLogo} alt="logo matexi" full />
                    </Logo>
                  )}
                  {/* <SocialMedia /> */}
                </StyledInnerWrapper>
                <LinksWrapper>
                  {matchesXL ? <Links small /> : <MenuBtn />}
                </LinksWrapper>
              </ContentInnerWrapper>
            </InnerWrapper>
          </StyledContent>
          <ImageWrapper>
            <LazyImage minHeight="650px" src={image} alt={imageAlt} cover />
          </ImageWrapper>
        </ScrollableSection>
      </Wrapper>
    )
  );
};

Header.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Header;
